<template>
    <modal-lateral ref="modalInformacionCompra" titulo="Informacion de Compra" porcentaje="40%">
        <ValidationObserver ref="validacion">
            <div class="row mx-0">
                <div class="col-auto px-2">
                    <img :src="retiro.foto_firmada" class="border obj-cover br-12 p-2" width="100" height="100" />
                </div>
                <div class="col tres-puntos d-flex flex-column justify-content-around">
                    <p class="text-general f-14 f-600 tres-puntos">
                        {{ retiro.sku }} - {{ retiro.producto }}
                    </p>
                    <p class="">
                        {{ retiro.presentacion }}
                    </p>
                    <p class="text-general f-18 f-600">
                        {{ separadorNumero(retiro.valor) }}
                    </p>
                </div>
                <div class="col-xl-3 px-2 col-lg">
                    <p class="text-general f-18 text-center">
                        Cantidad
                    </p>
                    <p class="f-14 text-center">
                        {{ agregarSeparadoresNumero(retiro.cantidad_compra) }}
                    </p>
                    <p class="text-general f-18 text-center mt-3">
                        Valor Total
                    </p>
                    <p class="f-14 text-center">
                        {{ separadorNumero(retiro.total_compra) }}
                    </p>
                </div>
            </div>
            <div class="custom-scroll overflow-auto pt-3" style="height:calc(100vh - 235px)">
                <div class="row mx-0 mb-3">
                    <div class="col-auto">
                        <img :src="retiro.usuario_compra.foto" class="obj-cover rounded-circle border-general2" width="42" height="42" />
                    </div>
                    <div class="col mr-2 br-5 py-2 bg-light-f5 border">
                        <div class="row mx-0 mb-2">
                            <p class="col text-general f-15 f-500 px-0">
                                <strong>
                                    Compra
                                </strong>
                            </p>
                            <p class="col-auto px-0 text-general f-15 f-500">
                                {{ retiro.usuario_compra.nombre }}
                            </p>
                            <p class="col-auto f-15 text-general pr-0 pl-1">
                                {{ formatearFecha(retiro.fecha_compra,'DD/MM/Y') }}
                            </p>
                        </div>
                        <p>
                            {{ retiro.comentario_compra }}
                        </p>
                    </div>
                </div>
                <div class="row mx-0 mb-3">
                    <div class="col-auto">
                        <img :src="retiro.usuario_retiro.foto" class="obj-cover rounded-circle border-general2" width="42" height="42" />
                    </div>
                    <div class="col mr-2 br-5 py-2 bg-light-f5 border">
                        <div class="row mx-0 mb-2">
                            <p class="col text-general f-15 px-0">
                                <strong>
                                    {{ retiro.tipo_nombre }}:
                                </strong>
                                {{ agregarSeparadoresNumero(retiro.cantidad) }} Uni. {{ separadorNumero(retiro.total) }}
                            </p>
                            <p class="col-auto px-0 text-general f-15 f-500">
                                {{ retiro.usuario_retiro.nombre }}
                            </p>
                            <p class="col-auto f-15 text-general pr-0 pl-1">
                                {{ formatearFecha(retiro.fecha_retiro,'DD/MM/Y') }}
                            </p>
                        </div>
                        <p v-if="retiro.tipo != 1" class="text-general f-14">
                            <strong> A Cedis :</strong> {{ retiro.cedis_destino }}
                        </p>
                        <p>
                            {{ retiro.comentario_retiro }}
                        </p>
                    </div>
                </div>

                <div v-if="retiro.estado == 0 || retiro.estado == 2" class="col-12 my-2">
                    <ValidationProvider v-slot="{errors}" rules="required|max:500" name="observación">
                        <label class="pl-3 f-14"> Observación </label>
                        <el-input v-model="retiro.estado_comentario" class="w-100" type="textarea" :rows="5" placeholder="Escribe tu observación" maxlength="500" show-word-limit />
                        <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                    </ValidationProvider>
                </div>
                <div v-else class="row mx-0 mb-3">
                    <div class="col-auto">
                        <img :src="retiro.usuario_confirma.foto" class="obj-cover rounded-circle border-general2" width="42" height="42" />
                    </div>
                    <div class="col mr-2 br-5 py-2 bg-light-f5 border">
                        <div class="row mx-0 mb-2">
                            <p class="col text-general f-15 f-500 px-0">
                                <strong>
                                    Observación
                                </strong>
                            </p>
                            <p class="col-auto px-0 text-general f-15 f-500">
                                {{ retiro.usuario_confirma.nombre }}
                            </p>
                            <p class="col-auto f-15 text-general pr-0 pl-1">
                                {{ formatearFecha(retiro.fecha_confirma,'DD/MM/Y') }}
                            </p>
                        </div>
                        <p class="salto-linea">
                            {{ retiro.estado_comentario }}
                        </p>
                    </div>
                    <div v-if="retiro.estado == 3" class="col-12 px-0">
                        <div class="row mx-0">
                            <div class="col-auto" />
                            <p class="col pl-5">
                                <i class="icon-format-list-checks text-orange" /> El retiro tuvo una revision previa
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="(retiro.estado != 3 || retiro.estado != 1) && $can('botones_gestion_admin_retiros_gestionar')" class="row mx-0 justify-content-center py-2">
                <div v-if="retiro.estado == 0" class="btn-outline px-5 mr-1" @click="gestionRetiro(2)">
                    Revisar
                </div>
                <div v-if="retiro.estado == 0" class="btn-general px-5 ml-1" @click="gestionRetiro(1)">
                    Aprobar
                </div>
                <div v-else-if="retiro.estado == 2" class="btn-general px-5 ml-1" @click="gestionRetiro(3)">
                    Aprobar
                </div>
            </div>
        </ValidationObserver>
    </modal-lateral>
</template>

<script>
import { mapGetters } from 'vuex'
import Retiros from "~/services/retiros";

export default {
    data(){
        return {
            img_url: 'https://vixark.b-cdn.net/lp-i-i-p/mini-brownies-rellenos-bimbo-220g-arequipe.jpg',
            observacion: '',
            id_retiro: null,
            tab: null,
            retiro: {
                valor: null,
                cantidad_compra: null,
                comentario_compra: null,
                usuario_compra: {
                    nombre: '',
                    foto: ''
                },
                fecha_compra: null,
                id: null,
                cantidad: null,
                tipo: null,
                estado: null,
                estado_comentario: null,
                comentario_retiro: null,
                usuario_retiro: {
                    nombre: '',
                    foto: ''
                },
                fecha_retiro: null,
                usuario_confirma: {
                    nombre: '',
                    foto: ''
                },
                fecha_confirma: null,
                sku: null,
                producto: null,
                presentacion: null,
                cedis_destino: null,
                total: null,
                total_compra: null,
                origen: null,
                tipo_nombre: null,
                foto_firmada: null
            }
        }
    },
    computed: {
        ...mapGetters({
            id_cedis:'cedis/id_cedis',
            id_moneda:'cedis/id_moneda',
        }),
    },
    methods: {
        toggle(id_retiro, tab){
            this.id_retiro = id_retiro
            this.tab = tab
            this.infoModalRetiro()
            this.$refs.modalInformacionCompra.toggle();
        },
        async infoModalRetiro(){
            try {
                let params = {
                    tab: this.tab,
                    id_moneda: this.id_moneda,
                    id_cedis: this.id_cedis
                }
                const { data } = await Retiros.infoModalRetiro(this.id_retiro, params)
                this.retiro = data.retiro
            } catch (error){
                this.error_catch(error)
            }
        },
        async gestionRetiro(estado){
            try {
                const valid = await this.$refs.validacion.validate()
                if(!valid){
                    this.notificacion('Mensaje', 'campos obligatorios', 'warning')
                    return false
                }
                let params = {
                    estado,
                    estado_comentario: this.retiro.estado_comentario,
                }
                const { data } = await Retiros.gestionRetiro(this.id_retiro, params)
                this.notificacion('Mensaje', data.message, 'success')
                this.$emit('listar')
                this.$refs.modalInformacionCompra.toggle();

            } catch (error){
                this.error_catch(error)
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.br-12{
    border-radius: 12px !important;
}
.triangle{
    position: absolute;
    z-index: 1;
    top: 9px;
    left: -20px;
    width: 0px;
    height: 0px;
    border-left: 5px solid transparent;
    border-right: 17px solid var(--color-light);
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
}
.br-12{
    border-radius: 12px !important;
}
</style>
